import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation, { FamilleButton, MenuButton } from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
import BigButton from '../../components/Ui/BigButton'

// markup
const Parvis = () => {
  const metaTitle = 'Parvis du Festif! | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Accès gratuit ! Nourriture charlevoisienne, alcool québecois et DJ sets'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, parvis, gratuit'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Parvis du Festif!"
        title2="ICI Musique"
      />
      <div className="container content parvis pb-12">

        {/* TOP VIDEO */}
        <div className="max-w-[1920px] justify-center mx-auto">
          <center>
            <div className="text-center w-full">
              <video muted autoPlay loop playsInline className="rounded-3xl">
                <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdgVUMTzAJOCiiJ_parvis.mp4" type="video/mp4" />
              </video>
            </div>
          </center>
        </div>

        {/* HIGHLIGHTED TEXT */}
        <div className='my-12 text-center'>
          <h2>C’est ici que se trouve le cœur du Festif! C’est le lieu de rendez-vous pour rejoindre ta gang, pour se ravitailler, pour prendre un break ou pour te trémousser un brin: bref, bienvenue au Parvis Ici Musique, l’épicentre du festival !</h2>
        </div>

        {/* HORAIRE */}
        <div className='md:text-left text-center'>
          <h4 className='pb-2'>Horaires d’ouverture</h4>
          <p className='pb-1'>Mercredi : 17h00 à 01h00</p>
          <p className='pb-1'>Jeudi : 12h00 à 03h00</p>
          <p className='pb-1'>Vendredi : 12h00 à 3h00</p>
          <p className='pb-1'>Samedi : 12h00 à 3h00</p>
          <p className='pb-1'>Dimanche : 12h00 à 18h00</p>
          <p className='italic pt-2'>Accès gratuit, aucun billet nécessaire</p>
        </div>

        {/* LOGO INFOS */}
        <div className='list-none flex justify-center items-center flex-wrap max-w-[1200px] mx-auto mt-6'>
          <FamilleButton />
          <MenuButton />
        </div>

        <div>
          <h2 className='uppercase text-center'>Musique et ambiance</h2>
          <p>Entrevue d’artiste, DJ Sets flamboyants, musique d’ambiance et piste de danse emblématique sont au rendez-vous. L’espace parfait pour te dégourdir les jambes !</p>
          <h3 className='py-2'>Les DJ Sets ICI Musique</h3>
          <p>Vibre au rythme des DJ sets enflammés d'ICI Musique sur le Parvis. De jeudi à samedi, de 15h à 19h, le Parvis du Festif! sera l'épicentre des festivités grâce à ses DJ sets propulsés par les coups de cœur musicaux des collaboratrices d'ICI MUSIQUE, Catherine Pogonat et Sarahmée.</p>

          {/* LOGO ICI MUSIQUE */}
          <div className="md:px-[30px] px-[10px] text-center py-9">
            <a href="https://ici.radio-canada.ca/ohdio/musique" target="_blank" rel="noreferrer noopener">
              <StaticImage src="../../images/logo_ici_musique.png" alt="Logo ICI-Musique" />
            </a>
          </div>
        </div>

        <div className="billetterie flex flex-wrap w-full">
          {/* SECTION 1 */}
          <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
            <div className="bg-white rounded-3xl">
              <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                <h3 className="leading-none mb-3">Mercredi<br/>17 juillet</h3>
              </div>
              <div className="py-4 px-6">
                <p className="mb-4 !px-2 !text-green-wolf2024"><b>17h30</b><br/><a className="hover:underline !text-green-wolf2024" href="/artistes/dufff/">Düff</a></p>
                <p className="mb-4 !px-2 !text-green-wolf2024"><b>18h15</b><br/><a className="hover:underline !text-green-wolf2024" href="/artistes/maude-arsenault-dj">Maude Arsenault DJ</a></p>
                <p className="mb-4 !px-2 !text-green-wolf2024"><b>20h00</b><br/><a className="hover:underline !text-green-wolf2024" href="/artistes/lost-camper">Lost Camper</a></p>
                <p className="mb-4 !px-2 !text-green-wolf2024"><b>20h45</b><br/><a className="hover:underline !text-green-wolf2024" href="/artistes/joanna-magik">Joanna Magik</a></p>
                <p className="mb-4 !px-2 !text-green-wolf2024"><b>22:45</b><br/><a className="hover:underline !text-green-wolf2024" href="/artistes/funkyfalz">FunkyFalz</a></p>
              </div>
            </div>
          </div>

          {/* SECTION 2 */}
          <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
            <div className="bg-white rounded-3xl">
              <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
              <h3 className="leading-none mb-3">Jeudi<br/>18 juillet</h3>
              </div>
              <div className="py-4 px-6">
                <p className="mb-4 !px-2 !text-blue-wolf2024"><b>15h00</b><br/><a className="hover:underline !text-blue-wolf2024" href="/artistes/sarahmee-dj-set">DJ Set Ici Musique Sarahmée</a></p>
                <p className="mb-2 !px-2 !text-blue-wolf2024"><b>17h00</b><br/><a className="hover:underline !text-blue-wolf2024" href="/artistes/catherine-pogonat">DJ Set Ici Musique Catherine Pogonat avec Yannick Roberge</a></p>
              </div>
            </div>
          </div>

          {/* SECTION 3 */}
          <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
            <div className="bg-white rounded-3xl">
              <div className="bg-pink-wolf2024 rounded-t-3xl px-1 py-6 text-white">
              <h3 className="leading-none mb-3">Vendredi<br/>19 juillet</h3>
              </div>
              <div className="py-4 px-6">
                <p className="mb-4 !px-2 !text-pink-wolf2024"><b>15h00</b><br/><a className="hover:underline !text-pink-wolf2024" href="/artistes/sarahmee-dj-set">DJ Set Ici Musique Sarahmée</a></p>
                <p className="mb-2 !px-2 !text-pink-wolf2024"><b>17h00</b><br/><a className="hover:underline !text-pink-wolf2024" href="/artistes/catherine-pogonat">DJ Set Ici Musique Catherine Pogonat avec Yannick Roberge</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="billetterie flex flex-wrap w-full md:mt-9 mt-0 mb-12">

          <div className='md:w-1/6 w-0 text-center px-4 my-0'></div>

          {/* SECTION 4 */}
          <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
            <div className="bg-white rounded-3xl">
              <div className="md:bg-red-wolf2024 bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                <h3 className="leading-none mb-3">Samedi<br/>20 juillet</h3>
              </div>
              <div className="py-4 px-6">
                <p className="mb-4 !px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>12h30</b><br/><a className="hover:underline md:!text-red-wolf2024 !text-green-wolf2024" href="/artistes/julia-perron">Josh & The Dirty Rags</a></p>
                <p className="mb-4 !px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>15h00</b><br/><a className="hover:underline md:!text-red-wolf2024 !text-green-wolf2024" href="/artistes/vague-de-cirque">DJ Set Ici Musique Sarahmée</a></p>
                <p className="mb-4 !px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>17h00</b><br/><a className="hover:underline md:!text-red-wolf2024 !text-green-wolf2024" href="/artistes/julia-perron">DJ Set Ici Musique Catherine Pogonat avec Yannick Roberge</a></p>
                <p className="mb-4 !px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>19h00</b><br/><a className="hover:underline md:!text-red-wolf2024 !text-green-wolf2024" href="/artistes/vague-de-cirque">Arielle Roberge</a></p>
              </div>
            </div>
          </div>

          {/* SECTION 5 */}
          <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
            <div className="bg-white rounded-3xl">
              <div className="md:bg-green-wolf2024 bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
              <h3 className="leading-none mb-3">Dimanche<br/>21 juillet</h3>
              </div>
              <div className="py-4 px-6">
                <p className="mb-4 !px-2 md:!text-green-wolf2024 !text-blue-wolf2024"><b>12h30</b><br/><a className="hover:underline md:!text-green-wolf2024 !text-blue-wolf2024" href="/artistes/good-trouble-brass-band">Trio Jazz Minouche</a></p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className='uppercase text-center'>Développement durable</h2>

          <h3 className='py-2'>Kiosque de développement durable RECYC-QUÉBEC</h3>
          <p>Plusieurs organismes et acteur·ices en lien avec le développement durable se réunissent pour te jaser d’idées, de stratégies et d’actions qui contribuent au bien-être de notre planète et de notre communauté. Conférences, 5 à 7, ateliers, sondages et marché public y seront toute la fin de semaine, passe nous voir !</p>

          <div className='text-center mt-6'>
            <StaticImage className="my-4 max-h-[50px]" imgClassName="!object-contain object-left" src="../images/partenaires/recyc-quebec-logo.png" alt="Logo Recyc-Québec" placeholder="none"/>
          </div>

          <div className="max-w-[800px] text-center flex pt-8 mx-auto">
            <StaticImage src="../../images/conference.jpg" alt="Photo des conféférences" />
          </div>

          <div className="billetterie flex flex-wrap w-full mt-9">

            {/* SECTION 1 */}
            <div className="md:w-1/4 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3 !text-[25px]">Jeudi<br/>18 juillet</h3>
                </div>
                <div className="py-4 px-6">
                  <p className="mb-4 !px-2 !text-green-wolf2024"><b>17h à 19h</b><br/>Kiosque de développement durable RECYC-QUÉBEC: Organisation Bleue <br/><br/><span className='italic'>Virée du St-Laurent et inscriptions pour nettoyage des berges du vendredi am</span></p>
                </div>
              </div>
            </div>

            {/* SECTION 2 */}
            <div className="md:w-1/4 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                <h3 className="leading-none mb-3 !text-[25px]">Vendredi<br/>19 juillet</h3>
                </div>
                <div className="py-4 px-6">
                  <p className="mb-4 !px-2 !text-blue-wolf2024"><b>10h à 12h</b><br/>Plage de Baie-Saint-Paul: Nettoyage des berges à la Plage de Baie-Saint-Paul avec Organisation Bleue </p>
                  <p className="mb-2 !px-2 !text-blue-wolf2024"><b>13h30 à 17h</b><br/>Kiosque de développement durable RECYC-QUÉBEC: Région de la Biosphère de Charlevoix <br/><br/><span className='italic'>Présentation de l'organisme, mission et implication dans la région</span></p>
                  <p className="mb-2 !px-2 !text-blue-wolf2024"><b>12h00 à 18h00</b><br/>Kiosque de développement durable RECYC-QUÉBEC: Musée d’art contemporain <br/><br/><span className='italic'>Atelier artistique en continu - Viens dessiner ta vision d'un Festif! vert (Grande fresque exposée au kiosque)</span></p>
                </div>
              </div>
            </div>

            {/* SECTION 3 */}
            <div className="md:w-1/4 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-pink-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                <h3 className="leading-none mb-3 !text-[25px]">Samedi<br/>20 juillet</h3>
                </div>
                <div className="py-4 px-6">
                  <p className="mb-4 !px-2 !text-pink-wolf2024"><b>10h00 à 13h30</b><br/>Kiosque de développement durable RECYC-QUÉBEC: Agence Blue Marble avec Guillaume Philippe <br/><br/><span className='italic'>Historique du développement durable au Festif! - Les bons coups … et l’apprentissage!</span></p>
                  <p className="mb-2 !px-2 !text-pink-wolf2024"><b>13h30 à 17h00</b><br/>Kiosque de développement durable RECYC-QUÉBEC : Pure <br/><br/><span className='italic'>Présentation de l'entreprise et produits + Nettoyage chaussures</span></p>
                </div>
              </div>
            </div>

            {/* SECTION 4 */}
            <div className="md:w-1/4 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3 !text-[25px]">Dimanche<br/>21 juillet</h3>
                </div>
                <div className="py-4 px-6">
                  <p className="mb-4 !px-2 !text-green-wolf2024"><b>10h à 18h</b><br/>Kiosque DD: Mini Marché <br/><br/><span className='italic'>Vente de légumes frais et locaux, rencontre avec les producteurs et productrices de la région. Les Thés de Charlevoix, Chacun son Pain, Ferme Marie-Noëlle Beaulieu, Jardin de la Simplicité, Baie-Saint-Pomme.</span></p>
                </div>
              </div>
            </div>
          </div>

          <h3 className='py-2'>Moment à confirmer - Espace Famille</h3>
          <p>Marie-Sophie Bertrand<br/><span className='italic'>Activité ludique éco responsable pour enfants</span></p>

          <h3 className='py-2'>Tout le weekend -  Kiosque de développement durable RECYC-QUÉBEC</h3>
          <p><span className='italic'>Discussions sur la politique DD du Festif! - Historique de la démarche</span></p>
          <p><span className='italic'>Comment devenir un·e festivalier·ère vert·e</span></p>
          <p><span className='italic'>Sondages et suggestions</span></p>

        </div>

        <div>
          <h2 className='uppercase text-center'>Zone chill KARIBU</h2>
          <div className='my-12 text-center'>
            <p>Besoin d’un endroit confo pour relaxer entre copains et copines ? Karibu fournit des hamacs et des bean bags au plus grand plaisir des festivalier·ères en quête d’un endroit cosy.</p>
          </div>
        </div>

        {/* PROGRAMMATION */}
        <div className='w-full flex text-center'>
          <p className="mx-auto"><BigButton href="/programmation" text="Programmation" largeText={false}/></p>
        </div>

        {/* BOUTON DE RETOUR */}
        <div className='w-full flex text-center'>
          <p className="mx-auto"><BigButton href="/infos/scenes#info_content" text="Revenir aux scènes" largeText={false} target='_self'/></p>
        </div>

      </div>
    </Wolflayout>
  )
}

export default Parvis
